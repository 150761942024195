<template>
  <div>
    <b-avatar
      :src="src"
      :size="size"
      rounded
    />
    <BaseFileInput
      class="d-inline"
      @change="handleFileInputChange"
    >
      <b-button
        variant="primary"
        class="text-nowrap rounded-sm px-2 mx-1"
      >
        Actualizar
      </b-button>
    </BaseFileInput>
    <b-button
      v-show="src !== null"
      variant="danger"
      class="text-nowrap rounded-sm px-2"
      @click="handleDeleteImageButtonClick"
    >
      Borrar
    </b-button>
  </div>
</template>

<script>
import BaseFileInput from '@/components/ui/file/BaseFileInput.vue'

export default {
  name: 'BaseImageFileInput',
  components: { BaseFileInput },
  props: {
    value: {
      type: [String, Blob, MediaSource, Array],
      default: null,
    },
    size: {
      type: String,
      default: '90px',
    },
  },
  computed: {
    src() {
      if (this.value instanceof Blob || this.value instanceof MediaSource) {
        return URL.createObjectURL(this.value)
      }

      if (this.value instanceof Array) {
        return this.value.length === 0 || !this.value[0] ? null : URL.createObjectURL(this.value[0])
      }

      return this.value
    },
  },
  methods: {
    handleDeleteImageButtonClick() {
      this.$emit('input', null)
      this.$emit('delete-image')
    },
    handleFileInputChange(files) {
      if (!files || files.length === 0) {
        return
      }

      this.$emit('input', files[0])
    },
  },
}
</script>

<style scoped>
</style>
