<template>
  <validation-observer ref="product-form">
    <b-form class="p-2" @submit.prevent>
      <b-row>
        <b-col sm="12" md="6">
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Nombre"
                rules="required"
              >
                <b-form-group label="Nombre" label-for="product-name">
                  <b-form-input
                    :value="model.name"
                    name="product-name"
                    placeholder="Nombre"
                    :state="$getValidationState(validationContext)"
                    @input="update('name', $event)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Categoría" label-for="product-category">
                <CategorySelect
                  :value="model.product_category"
                  @option-selected="handleCategoryOptionSelected"
                  @clear="productAttributes = []"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Marca" label-for="product-brand">
                <b-form-input
                  :value="model.brand"
                  name="product-brand"
                  placeholder="Marca"
                  @input="update('brand', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Grupo" label-for="product-group">
                <b-form-input
                  :value="model.product_group"
                  name="product-group"
                  placeholder="Grupo"
                  @input="update('product_group', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Fabricante" label-for="product-maker">
                <b-form-input
                  :value="model.maker"
                  name="product-maker"
                  placeholder="Fabricante"
                  @input="update('maker', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Proveedor principal"
                label-for="product-main-provider"
              >
                <BaseSelect
                  id="product-main-provider"
                  :value="model.major_provider"
                  :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
                  @input="update('major_provider', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Proveedor secundario"
                label-for="product-secondary-provider"
              >
                <BaseSelect
                  id="product-secondary-provider"
                  :value="model.secondary_provider"
                  :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
                  @input="update('secondary_provider', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <ProductAttributeForm
                ref="product-attribute-form"
                :value="model.attribs"
                :product-attributes="
                  model.product_category
                    ? model.product_category.attrib_configurations
                    : []
                "
                @input="update('attribs', $event)"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="6">
          <b-row>
            <b-col sm="12">
              <b-form-group label="sku" label-for="sku">
                <b-form-input
                  :value="model.sku"
                  name="sku"
                  placeholder="sku"
                  @input="update('sku', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Estado"
                rules="required"
              >
                <b-form-group label="Estado">
                  <StatusSelect
                    :value="model.status"
                    :type="statusType"
                    @input="update('status', $event)"
                  />
                </b-form-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-checkbox
                :checked="model.purchase_product"
                class="pb-1"
                value="1"
                :unchecked-value="0"
                @input="update('purchase_product', $event)"
              >
                Producto de compra
              </b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Precio unitario" label-for="product-cost">
                <b-form-input
                  :value="model.purchase_price"
                  name="product-cost"
                  placeholder="Precio unitario"
                  @input="update('purchase_price', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Impuesto de compra"
                label-for="purchase-sale-tax"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :value="model.purchase_tax"
                    name="purchase-sale-tax"
                    placeholder="Impuesto de compra"
                    type="number"
                    @input="update('purchase_tax', $event)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-checkbox
                :checked="model.sale_product"
                class="pb-1"
                value="1"
                :unchecked-value="0"
                @input="update('sale_product', $event)"
              >
                Producto de venta
              </b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Precio unitario"
                label-for="product-sale-price"
              >
                <b-form-input
                  :value="model.sale_price"
                  name="product-sale-price"
                  placeholder="Precio unitario"
                  @input="update('sale_price', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Impuesto de venta"
                label-for="product-sale-tax"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    :value="model.sale_tax"
                    name="product-sale-tax"
                    placeholder="Impuesto de venta"
                    type="number"
                    @input="update('sale_tax', $event)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="PercentIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Adjuntar imagen del producto">
                <BaseImageFileInput
                  id="product-image"
                  :value="productImage"
                  @input="update('image', [$event])"
                  @delete-image="imageRemoved = true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { PRODUCT_STATUS_TYPE } from "@/api/status-api";
import CategoriesApi from "@/api/categories-api";
import FormMixin from "@/mixins/formMixin";
import CategorySelect from "@/components/category/select/CategorySelect.vue";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import ProductAttributeForm from "@/components/products/form/ProductAttributeForm.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import BaseImageFileInput from "@/components/ui/file/BaseImageFileInput.vue";

export default {
  name: "ProductForm",
  components: {
    BaseImageFileInput,
    BaseSelect,
    ProductAttributeForm,
    StatusSelect,
    CategorySelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      imageRemoved: false,
      statusType: PRODUCT_STATUS_TYPE,
    };
  },
  computed: {
    productImage() {
      if (this.model.image) {
        return this.model.image.path || this.model.image;
      }

      return null;
    },
  },
  methods: {
    async validateForm() {
      return this.$refs["product-form"].validate();
    },
    isImageRemoved() {
      return this.imageRemoved;
    },
    async handleCategoryOptionSelected(category) {
      const response = await CategoriesApi.get(category.id);
      const newProductCategory = response.data;
      newProductCategory.attrib_configurations =
        newProductCategory.attribConfigurations;

      this.update("product_category", newProductCategory);
    },
  },
};
</script>

<style scoped></style>
