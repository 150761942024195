<template>
  <validation-observer ref="product-attribute-form">
    <b-row>
      <b-col
        v-for="(productAttributeField, i) in productAttributeFields"
        :key="i"
        cols="12"
      >
        <ProductAttributeField
          :value="getValue(productAttributeField.id)"
          :type="productAttributeField.type"
          :label="productAttributeField.name"
          :options="productAttributeField.options ? productAttributeField.options.split(',') : []"
          :required="productAttributeField.required === 1"
          @input="updateModel(productAttributeField.id, $event)"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import ProductAttributeField from '@/components/products/product-attribute-field/ProductAttributeField.vue'

export default {
  name: 'ProductAttributeForm',
  components: { ProductAttributeField },
  props: {
    productAttributes: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    productAttributeFields() {
      if (!this.productAttributes) {
        return []
      }

      const result = this.productAttributes
      return result.sort((currentElement, nextElement) => currentElement.order - nextElement.order)
    },
  },
  methods: {
    validate() {
      return this.$refs['product-attribute-form'].validate()
    },
    getValue(key) {
      return this.value.find(item => this.isSameConfiguration(item, key))?.value || null
    },
    updateModel(key, newValue) {
      this.$emit('input', this.value.map(item => ({
        configuration_id: item.attrib_configuration?.id || item.configuration_id,
        value: this.isSameConfiguration(item, key) ? newValue : item.value,
      })))
    },
    isSameConfiguration(item, key) {
      return item.attrib_configuration?.id === key || item.configuration_id === key
    },
  },
}
</script>

<style scoped>
</style>
