<template>
  <validation-provider
    v-if="required"
    v-slot="validationContext"
    :name="label"
    rules="required"
  >
    <component
      :is="formFieldType"
      v-model="model"
      :label="label || ''"
      :options="options"
      :validation-context="validationContext"
      @change="onChange($event)"
    />
  </validation-provider>
  <component
    :is="formFieldType"
    v-else
    v-model="model"
    :label="label || ''"
    :options="options"
    @change="onChange($event)"
  />
</template>

<script>
export default {
  name: 'ProductAttributeField',
  components: {
    'input-field': () => import('@/components/products/product-attribute-field/ProductAttributeInput.vue'),
    'select-field': () => import('@/components/products/product-attribute-field/ProductAttributeSelect.vue'),
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
    formFieldType() {
      return `${this.type || 'input'}-field`
    },
  },
  methods: {
    onChange(eventPayload) {
      this.$emit('change', eventPayload)
    },
  },
}
</script>

<style scoped>
</style>
